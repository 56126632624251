<template>
  <vueper-slides fade :touchable="false" autoplay>
    <vueper-slide
      v-for="(slide, i) in slides"
      :key="i"
      :image="slide.image"
      :title="slide.title"
      :content="slide.content"
    />
  </vueper-slides>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import imagen1  from "../../public/imagenes/slide/portada5.webp";
import imagen2  from "../../public/imagenes/slide/portada1.webp";
import imagen3  from "../../public/imagenes/slide/portada2.webp";
import imagen4  from "../../public/imagenes/slide/portada3.webp";
import imagen5  from "../../public/imagenes/slide/portada4.webp";
import imagen6 from "../../public/imagenes/slide/PAGARENTA6.webp";
export default {
  components: { VueperSlides, VueperSlide },
  data() {
    return {
      slides: [
      {
          title: "",
          content: "",
          // You can also provide a URL for the image.
          image: imagen6
        },
        {
          title: "",
          content: "",
          // You can also provide a URL for the image.
          image: imagen1
        },
        {
          title: "",
          content: "",
          // You can also provide a URL for the image.
          image: imagen2
        },
        {
          title: "",
          content: "",
          // You can also provide a URL for the image.
          image: imagen3
        },
        {
          title: "",
          content: "",
          // You can also provide a URL for the image.
          image: imagen4
        },
        {
          title: "",
          content: "",
          // You can also provide a URL for the image.
          image: imagen5
        },
        // Other slides.
      ],
    };
  },
  methods: {},
  created() {},
};
</script>

<style>
</style>