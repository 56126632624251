<template>
  <div class="wrapper">
      <!-- <div class="tamanio">   -->
        <div class="contactus-2">
            <gmap-map
          id="map"
          class="map tamanio"
          :center="center"
          :zoom="zoom"
          :options="options"
          map-type-id="roadmap"
          style=" height: 800px"
        >
        <gmap-marker
            zoomControl: true,
            :key="u.id"
            v-for="u in Ubicaciones"
            :position="u"
            :clickable="true"
            :draggable="false"
            @click="Ubicacion(u.id)"
          ></gmap-marker> 
        </gmap-map>   
        <div class="col-lg-6 col-md-10">
            <card type="contact" raised header-classes="text-center">
                <h4 slot="header" class="card-title">Nuestras Representaciones</h4>
                <div class="row">
                  <div class="col-12">
                       <el-table
                        :data="tableData"
                        height="500"     
                        style="overflow-y:scroll"                                         
                        stripe>
                        <el-table-column
                          prop="representacionNombre"
                          label="Representación"
                          width="300">
                        </el-table-column>
                        <el-table-column min-width="150" header-align="right" label="">
                          <div slot-scope="{row}" class="text-right table-actions">
                              <n-button type="primary" @click.native="Ubicacion(row.representacionId)" size="sm" icon >
                                <i class="now-ui-icons ui-1_send"></i>
                              </n-button>
                          </div>
                        </el-table-column>
                      </el-table> 
                  </div>
                </div>
            </card>
        </div>
      </div>
  </div>
</template>
<script>
import ServicioRepresentacion from "../Servicios/Representaciones";
import {
  Badge,
  Button,
  InfoSection,
  FormGroupInput,
  Card,
  Tabs,
  TabPane,
  Checkbox,
} from "@/components";
import { API_KEY } from "@/constants";
import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";
import { Table, TableColumn } from "element-ui";
import store from "../store";
import ServUsuarios from "../Servicios/Usuarios";

Vue.use(VueGoogleMaps, {
  load: {
    key: API_KEY,
  },
});
export default {
  name: "findus",
  bodyClass: "findus",
  computed: {
    seeAll() {
      return store.state.seeAll;
    },
  },
  components: {
    InfoSection,
    Card,
    Tabs,
    TabPane,
    Badge,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [FormGroupInput.name]: FormGroupInput,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      Ubicaciones: [],
      form: {
        firstName: "",
        email: "",
        notRobot: false,
      }, //22.15849627381963, -112.2190274012515
      center: {
        lat: 22.15849627381963,
        lng: -112.2190274012515,
      },
      options: {
        styles: [
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [
              {
                color: "#e9e9e9",
              },
              {
                lightness: 17,
              },
            ],
          },
        ],
      },
      //tableData: [],
      ubicacionActuallatitude: null,
      ubicacionActuallongitude: null,
      zoom: 5,
      tableData: [],
    };
  },
  methods: {
    Ubicacion(id) {
      //console.log(id);
      switch (id) {
        // case 17:
        // this.$router.push("/Chapultepec/inicio");
        // break;
        case 31:
        this.$router.push("/SanAngel/inicio");
        break;
        default:
        this.tableData.forEach((t) => {
          if (t.representacionId == id) {
            let representacion = {
              representacionId: t.representacionId,
              representacionNombre: t.representacionNombre,
              direccion: t.direccion,
              telefonoOficina: t.telefonoOficina,
              oficinaEmisora: null,
              porcentaje: null,
              porcentajeAsesor: null,
              porcentajeEjecutivo: null,
              foranea: null,
              latitud: t.latitud,
              longitud: t.longitud,
            };
            store.state.representacion = representacion;
            this.Usuarios(t.representacionId);
          }
        });
        break;
      }
    },
    retrive() {
      this.tableData = [];
      ServicioRepresentacion.listado()
        .then((r) => {
          this.tableData = r.data;
          //this.tableData.sort(() => Math.random() - 0.5);
          console.log("rep", this.tableData);
          store.state.representaciones = this.tableData;
          r.data.forEach((e) => {
            let ubicacion = {
              id: e.representacionId,
              lat: parseFloat(e.latitud),
              lng: parseFloat(e.longitud),
            };
            if (e.latitud != null && e.longitud != null) {
              this.Ubicaciones.push(ubicacion);
            }
          });
          //this.caulculamoeinterpretamos();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retriveLocation() {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.ubicacionActuallatitude = position.coords.latitude;
          this.ubicacionActuallongitude = position.coords.longitude;
          this.caulculamoeinterpretamos();
        },
        (error) => {
          console.log(error.message);
          store.state.seeAll = false;
          this.retrive();          
        }
      );
    },
    getKilometros(lat2, lon2) {
      // this.ubicacionActual: {
      //   latitude: null,
      //   longitude: null,
      // },
      var lat1 = this.ubicacionActuallatitude;
      var lon1 = this.ubicacionActuallongitude;
      var R = 6378.137; //Radio de la tierra en km
      var dLat = this.rad(lat2 - lat1);
      var dLong = this.rad(lon2 - lon1);
      var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.rad(lat1)) *
          Math.cos(this.rad(lat2)) *
          Math.sin(dLong / 2) *
          Math.sin(dLong / 2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c;
      return d.toFixed(2); //Retorna tres decimales y calculado a Kilometros
    },
    rad(x) {
      return (x * Math.PI) / 180;
    },
    caulculamoeinterpretamos() {      
      if (
        this.ubicacionActuallatitude != null&&
        this.ubicacionActuallongitude != null
      ) {
        let auxUbicaciones = [];
        this.tableData.forEach((u) => {
          if (u.latitud != null && u.longitud != null) {
            let distancia = 0;
            distancia = this.getKilometros(u.latitud, u.longitud);
            if (distancia > 0) {
              let representacion = {
                representacionId: u.representacionId,
                representacionNombre: u.representacionNombre + " encuentranos a " +distancia + " Kms." ,
                distancia: distancia,
              };
              auxUbicaciones.push(representacion);
            }
          }
        });
        if (auxUbicaciones.length > 0) {
          this.tableData = auxUbicaciones;
          this.tableData.sort((a, b) => a.distancia - b.distancia);
        }
      } else {
        this.tableData.sort(() => Math.random() - 0.5); //ordenamos siempre de forma aleatoria cuando no existe geolocalización
      }
    },
    Usuarios(id) {
      console.log(id);
      ServUsuarios.listado(id)
        .then((u) => {
          let ejecutivo = [];
          ejecutivo = u.data;
          if(id == 24){
            let eje = {
              usurioEmail:"representaciones@polizajuridica.com.mx",
              usuarioNomCompleto: "Contactanos a nuestra oficina",
              usuarioTelefono: "55 53 62 82 42 - 55 52 20 69 07 - 55 53 62 07 53",
              usuarioCelular: "55 39 73 20 52"
            }
            ejecutivo.push(eje)
          }
          console.log(ejecutivo)
          store.state.Ejecutivos = ejecutivo;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  created() {
    this.retrive();
    this.retriveLocation();
  },
};
</script>
<style>
.tamanio {
  height: 100%;
}
</style>