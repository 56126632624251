<template>  
  <div class="wrapper Tarjeta">
    <card type="blog" card-body-classes="text-center">
      <img
        v-if="Eventos.imagen == null"
        slot="image"
        class="container-sm"
        src="imagenes/Capacitacion.webp"
      />
      <img v-else slot="image"    class="container-sm" :src="Eventos.imagen" />
      <h6 class="category text-danger">
        <i class="now-ui-icons media-2_sound-wave"></i> {{ Eventos.lugar }}
      </h6>
      <h5 style="font-size: 11px;" class="card-title">
        {{ Eventos.titulo }}
      </h5>
<!--      <p class="card-description">-->
<!--        {{ Eventos.descripcion }}-->
<!--      </p>-->
<!--      <p class="card-description">-->
<!--        {{ Eventos.fechaHoraInicio }}-->
<!--      </p>-->
<!--      <p class="card-description">-->
<!--        {{ Eventos.fechaHoraFin }}-->
<!--      </p>-->
      <div class="card-footer">
        <n-button type="primary" @click.native="abrimosOtraVentana()"
          >Registrarse</n-button>
      </div>
    </card>
    <modal :show.sync="showModal">
       <div>
         <form action="" >
          <h2 style="color: black;">Registro de Eventos</h2>
          <div class="form-group">
  <div style="display: flex;">
    <div style="flex: 1;">
      <label for="nombre" style="display: block;">Nombre</label>
      <input type="text" v-model="nombre" class="input" style="max-width: 200px;">
    </div>
    <div style="flex: 1;">
      <label for="apellido" style="display: block;">Apellido Paterno</label>
      <input type="text" v-model="apellidoPaterno" class="input" style="max-width: 200px;">
    </div>
  </div>
</div>
    <div class="form-group">
      <label for="email">Correo Electrónico</label>
      <div>
        <input type="email" id="email" class="input" v-model="email">
      </div>
    </div>
    <div class="form-group">
      <label for="fecha">Numero Celular</label>
      <div>
        <input type="text" id="fecha" class="input" v-model="celular">
      </div>
    </div>
    <div class="form-group">
      <label for="seleccion">¿Viene de Parte de una Representacion?</label>
      <div>
    <label style="padding-right: 20px; padding-left: 10px;"><input type="radio" name="representacion" value="1" v-model="seleccion" style=" cursor: pointer; width: 15px; height: 15px;"> Si</label>
    <label ><input type="radio" name="representacion" value="2" v-model="seleccion" style="cursor: pointer; width: 15px; height: 15px;"> No</label>
     </div>
    </div>
    <div v-if="seleccion == 1" class="form-group">
      <label for="descripcion">Representacion</label>
      <div>
        <select style="cursor: pointer;" id="descripcion" class="input" v-model="representacionId">
          <option  v-for="e in lista"
                    :key="e.representacionId"
                    :value="e.representacionId"
                  >
                  {{ e.representacionNombre }}
                </option>
        </select>
    
      </div>
    </div>
    <div v-if="representacionId != 0 && seleccion == 1" class="form-group">
      <label for="descripcion">Representante</label>
      <div>
        <select style="cursor: pointer;"  class="input" v-model="usuarioId" >
          <option  v-for="u in lista2"
                    :key="u.representacionId"
                    :value="u.representacionId"
                  >
                  {{ u.usuarioNomCompleto }}
                </option>
        </select>
    
      </div>
    </div>
         </form>
       </div>
      <template slot="footer">
    <n-button style="width: 100px; height: 40px; font-size: 15px;" type="danger" @click.native="showModal = false">
      Cerrar
    </n-button>
    <n-button style="width: 100px; height: 40px; font-size: 15px;" type="primary" @click.native="submitForm()">
      Enviar
    </n-button>
  </template>
</modal>
  <modal :show.sync="showModal2">
  <div style="text-align: center;">
    <h3 style="color: black; text-align: center;">Registro Exitoso</h3>
    <img src="../../public/imagenes/aprobado.png" alt="Palomita de éxito" style="width: 120px; height: 120px; ">
  </div>
    <div  style=" text-align: center; margin-top: 3rem;">
    <n-button style="width: 100px; height: 40px; font-size: 15px;"  type="danger" @click.native="showModal2 = false">
      Aceptar
    </n-button>
   </div>
   </modal>
</div>
</template>

<script>
import { Card, Button, Modal, FormGroupInput } from "@/components";
import servRep from "@/Servicios/Representaciones.js";
import servEvento from "@/Servicios/EventoUsuario.js";
import servUsuarios from "@/Servicios/Usuarios.js";
export default {
  props: ["Evento", "modals", "Id"],
  data() {
    return {
      id: 0,
      eventosId: 0,
      estadoId : 1,
      Eventos: {},
      nombre: "",
      apellidoPaterno: "",
      email:"",
      celular: "",
      representacionId: 0,
      showModal : false,
      seleccion: 0,
      lista: [],
      lista2: [],
      showModal2 : false,
      usuarioId : 0,
    };
  },
  components: {
    Modal,
    Card,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },
  methods: {
    getData(){
     servRep.listado()
     .then((r) => {
      this.lista = r.data;
     });
    },
    getUser(){
      servUsuarios.getid(this.representacionId)
      .then((r) => {
        this.lista2 = r.data;
      });
    },
    abrimosOtraVentana() {
          this.showModal = true;
    },
    closeModal(){
       this.showModal = false;
    },
    submitForm(){
      const idRep = 10;
      if( this.seleccion == 2){
        this.usuarioId = idRep;
      }
      let data = {
        Id : this.id,
        Nombre : this.nombre, 
        Correo : this.email,
        celular : this.celular,
        ApellidoPaterno : this.apellidoPaterno,
        RepresentanteId : this.usuarioId,
        EventosId : this.Eventos.eventosId,
        EstadoId : this.estadoId,
      };
      servEvento.post(data).
      then((r) => {
        console.log(r);
        this.showModal = false;
        this.showModal2 = true;
        this.representacionId = 0;
        this.usuarioId = 0;
      })
    },
    
  },
  created() {
    this.getData();
    this.Eventos = this.Evento;

  },
  watch: {
    representacionId(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getUser();
      }
    },
  },
};
</script >

<style scoped>
.wrapper{
  width: 130%;
  height: 450px;
}
.container-sm{
  width: 100%;
  max-height: 220px;
  padding : 0 53px;
}
.Tarjeta {
  margin-top: 2.5%;

}

.form-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

.label {
  font-weight: bold;
}

.input {
  padding: 3px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 100%; 
}
</style>
