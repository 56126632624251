<template>
  <!--     *********     FEATURES 3      *********      -->
  <div class="features-3">
    <div class="container">
      <div class="row">
        <div class="col-md-8 ml-auto mr-auto">
          <h2 class="title colorTxt">Solicitudes</h2>
          <h3 class="description">
            <strong>
            Ahora es mas facil, simple e inteligente, contratar nuestros
            servicios.
            </strong>
          </h3>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="info info-hover">
            <div class="icon icon-primary icon-circle">
              <img
                slot="image"
                class="img rounded"
                src="imagenes/arrendador.webp"
              />
            </div>
            <h4 class="info-title">Arrendador</h4>
            <p class="description">
<strong>
              En el seguiente formato se solicitan los datos necesarios del
              Arrendador (Propietario)
              </strong>
            </p>
            <n-button type="primary" outline round @click.native="formatos(1)">
              <i class="fa fa-heart"></i> Descargar
            </n-button>
            <modal
              :show.sync="modals.classic"
              headerClasses="justify-content-center"
            >
              <template>
                <div class="Modal-magintop">
                  <pdf :src="src"></pdf>
                </div>
              </template>
              <template slot="footer">
                <a :href="src" target="_blank">Descargar</a>
                <n-button type="danger" @click.native="modals.classic = false"
                  >Cerrar</n-button
                >
              </template>
            </modal>
          </div>
        </div>
        <div class="col-md-4">
          <div class="info info-hover">
            <div class="icon icon-primary icon-circle">
              <img
                slot="image"
                class="img rounded"
                src="imagenes/arrendatarios.webp"
              />
            </div>
            <h4 class="info-title">Arrendatario</h4>
            <p class="description">
              <strong>
              En el siguiente formato se solicitan los datos del Arrendatario
              (Inquilino) favor de seleccionar acorde a su personalidad
              jurídica.
              </strong>
            </p>
            <n-button type="primary" outline round @click.native="formatos(2)">
              <i class="fa fa-heart"></i> Persona Fisica
            </n-button>
            <n-button type="primary" outline round @click.native="formatos(3)">
              <i class="fa fa-heart"></i> Persona Moral
            </n-button>
          </div>
        </div>
        <div class="col-md-4">
          <div class="info info-hover">
            <div class="icon icon-primary icon-circle">
              <img
                slot="image"
                class="img rounded"
                src="imagenes/soluciones.webp"
              />
            </div>
            <h4 class="info-title">Soluciones</h4>
            <p class="description">
              <strong>
              En el siguiente formato se piden los datos de las personas que
              estan incumplento al contrato de prestación de servicios (Póliza
              Jurídica)
              </strong>
            </p>
            <n-button type="primary" outline round @click.native="formatos(6)">
              <i class="fa fa-heart"></i> Descargar
            </n-button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="info info-hover">
            <div class="icon icon-primary icon-circle">
              <img
                slot="image"
                class="img rounded"
                src="imagenes/renovacion.webp"
              />
            </div>
            <h4 class="info-title">Renovación</h4>
            <p class="description">
              <strong>
              En el siguiente formato se solicitan los datos para renovar la
              póliza jurídica.
              </strong>
            </p>
            <n-button type="primary" outline round @click.native="formatos(4)">
              <i class="fa fa-heart"></i> Arrendador
            </n-button>
            <n-button type="primary" outline round @click.native="formatos(5)">
              <i class="fa fa-heart"></i> Arrendatario
            </n-button>
          </div>
        </div>
        <div class="col-md-6">
          <div class="info info-hover">
            <div class="icon icon-primary icon-circle">
              <img
                slot="image"
                class="img rounded"
                src="imagenes/precios.webp"
              />
            </div>
            <h4 class="info-title">Lista de Precios</h4>
            <p class="description">
              <strong>
              Podrás consultar los precios a través de tu monto de renta.
              </strong>
            </p>
            <n-button type="primary" outline round @click.native="formatos(7)">
              <i class="fa fa-heart"></i> Descargar
            </n-button>
            <!-- <n-button type="primary" outline round to="/Arrendatario">
              <i class="fa fa-heart"></i> En línea
            </n-button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--     *********    END FEATURES 3      *********      -->
</template>

<script>
import { Button, Modal } from "@/components";
import store from "../store";
import pdf from "vue-pdf";

export default {
  computed: {
    representacion() {
      return store.state.representacion;
    },
  },
  components: {
    Modal,
    [Button.name]: Button,
    pdf,
  },
  data() {
    return {
      modals: {
        classic: false,
      },
      src: null,
    };
  },
  methods: {
    Prueba() {
      console.log(this.representacion);
    },
    formatos(tipo) {
      switch (tipo) {
        case 1:
          this.src = "https://storage-pj.s3.amazonaws.com/Formatos/OTORGAMIENTO.pdf";
          break;
        case 2:
          this.src = "https://storage-pj.s3.amazonaws.com/Formatos/2_FORMATO_PERSONA_FISICA2.pdf";
          break;
        case 3:
          this.src = "https://storage-pj.s3.amazonaws.com/Formatos/3_FORMATO_PERSONA_MORAL.pdf";
          break;
        case 4:
          this.src = "https://storage-pj.s3.amazonaws.com/Formatos/4_FORMATO_RENOVACION_ARRENDADOR.pdf";
          break;
        case 5:
          this.src = "https://storage-pj.s3.amazonaws.com/Formatos/1_FORMATO_RENOVACION_ARRENDATARIO.pdf";
          break;
        case 6: //soluciones
          this.src = "https://storage-pj.s3.amazonaws.com/Formatos/5_FORMATOSOLUCIONACTUALIZADO.pdf";
          break;
        case 7:
          this.src = "https://storage-pj.s3.amazonaws.com/Formatos/LISTA_PRECIOS_2021.pdf";
          break;
      }
      this.modals.classic = true;
    },
  },
  mounted() {},
};
</script>
<style scoped>
.Modal-magintop {
  margin-top: 10%;
}
.colorTxt {
  color: rgb(144, 25, 23);
}
</style>