<template>
  <div class="container">
          <iframe
            :src="src"
            width="100%"
            height="100"
            frameborder="0" 
            class="responsive-iframe"
            >
           </iframe>
    </div>
</template>

<script>

export default {
  name: 'MyIframe',
  components: {
  },
  data: () => ({
    myIframe: null,
    src: "https://polizajuridicachapultepec.com.mx/"
  }),
  methods: {
    onLoad() {
    }
  }
}
</script>
<style scoped>
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
</style>