<template>
    <div class="features-6">
      <div class="container">
        <div class="row">          
          <div class="col-md-10 ml-auto mr-auto text-center">
            <n-button type="primary" round>Mas de 17 años en el mercado nos respaldan</n-button>
          </div>
          <div class="col-md-8 ml-auto mr-auto text-center">                        
            <h2 class="title colorTxt">¿Por qué nosotros?</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="info info-horizontal">
              <div class="icon icon-primary">
                <i class="now-ui-icons ui-2_like"></i>
              </div>
              <div class="description">
                <h5 class="info-title">
                  Somos pioneros en dar protección jurídica a su Arrendamiento.                  
                </h5>
              </div>
            </div>
            
            <div class="info info-horizontal">
              <div class="icon icon-primary">
                <i class="now-ui-icons business_money-coins"></i>
              </div>
              <div class="description">
                <h5 class="info-title">
                  Costos Bajos
                  <br />
                  <strong>(SÍN DEDUCIBLES).</strong>
                </h5>
              </div>
            </div>
            <div class="info info-horizontal">
              <div class="icon icon-primary">
                <i class="now-ui-icons ui-2_chat-round"></i>
              </div>
              <div class="description">
                <h5
                  class="info-title "
                >Porque el Arrendatario también nos importa, buscamos una mediación sana y conveniente.</h5>
              </div>
            </div>
            <div class="info info-horizontal">
              <div class="icon icon-primary">
                <i class="now-ui-icons business_chart-pie-36"></i>
              </div>
              <div class="description">
                <h5 class="info-title">Aceptamos fiadores de las ciudades en donde tenemos presencia a través de nuestras representaciones.</h5>
              </div>
            </div>
            <div class="info info-horizontal">
              <div class="icon icon-primary">
                <i class="now-ui-icons users_single-02"></i>
              </div>
              <div class="description">
                <h5
                  class="info-title"
                >Fortalecemos la capacitación continua y gratuita para el sector inmobiliario.</h5>
              </div>
            </div>

          </div>
          <!-- imagen -->
          <div class="col-md-4">
            <div class="phone-container">
              <img v-lazy="telefono" />
            </div>
          </div>
          <!-- fin imagen -->
          <div class="col-md-4">
            <div class="info info-horizontal">
              <div class="icon icon-primary">
                <i class="now-ui-icons location_world"></i>
              </div>
              
              <div class="description">
                <h5 class="info-title">Contamos con más de 26 representaciones a nivel nacional.</h5>
              </div>

            </div>
            <div class="info info-horizontal">
              <div class="icon icon-primary">
                <i class="now-ui-icons sport_user-run"></i>
              </div>
              <div class="description">
                <h5 class="info-title">
                  Resolución de trámite en 2 días hábiles.
                </h5>
              </div>
            </div>
            <div class="info info-horizontal">
              <div class="icon icon-primary">
                <i class="now-ui-icons ui-2_time-alarm"></i>
              </div>
              <div class="description">
                <h5 class="info-title">Servicio en horas y días no hábiles (previa cita).</h5>
              </div>
            </div>
            <div class="info info-horizontal">
              <div class="icon icon-primary">
                <i class="now-ui-icons business_bank"></i>
              </div>
              <div class="description">
                <h5 class="info-title">Asesoría Legal sin costo.</h5>
              </div>
            </div>
            <div class="info info-horizontal">
              <div class="icon icon-primary">
                <i class="now-ui-icons sport_user-run"></i>
              </div>
              <div class="description">
                <h5
                  class="info-title"
                >Atención rápida y eficiente ante el incumplimiento al contrato de arrendamiento.</h5>
              </div>
            </div>
            <div class="info info-horizontal">
              <div class="icon icon-primary">
                <i class="now-ui-icons business_chart-bar-32"></i>
              </div>
              <div class="description">
                <h5
                  class="info-title"
                >Reporte del status de la solución a la controversia y del juicio correspondiente.</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import {Button} from '@/components'
import telefono from "../../public/imagenes/Final.webp"
export default {
components: {
    [Button.name]: Button
  },
  data() {
    return {
      telefono: telefono
    };
  }
}
</script>