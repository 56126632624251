<template>
  <div class="wrapper">
    
    <div class="contactus-2" v-bind:class="ObjetoClass.mapa">
      
      <gmap-map
        id="map"
        class="map"
        :center="center"
        :zoom="20"
        :options="options"
        map-type-id="roadmap"
      >
        <gmap-marker
          zoomControl: true,
          :position="center"
          :clickable="true"
          :draggable="false"
        ></gmap-marker>
      </gmap-map>
      <div class="col-lg-6 col-md-10" >
                <card type="contact" raised header-classes="text-center" >
                  
                      <h4 slot="header" class="card-title">{{representacion.representacionNombre}}</h4>
                    <p slot="header" class="card-title">Dirección: {{representacion.direccion}}</p>
                 
                    <div class="row tarjeta"  >
                        <div class="col-md-6" v-for="u in Ejecutivos" :key="u.usuariosId">
                            <card type="testimonial" plain>
                            <img v-if="u.imagen == null" slot="avatar" class="img img-raised" src="imagenes/RECURSOS/silueta.jpeg"/>
                            <img v-else slot="avatar" class="rounded-circle" :src="u.imagen"/>
                            <h3 class="card-title">{{u.usuarioNomCompleto}}</h3>
                            <h6 class="category text-primary">Ejecutiv@</h6>
                            <a class="card-description text-primary" :href="'mailto:'+u.usurioEmail">Email: {{u.usurioEmail}}
                            </a></br>
                            <a class="card-description text-primary" :href="'tel:'+u.usuarioCelular">
                              Celular: {{u.usuarioCelular}}
                            </a></br>
                            <a class="card-description text-primary" :href="'tel:'+u.usuarioTelefono">Teléfono Oficina: {{u.usuarioTelefono}}
                            </a>
                        </card>
                        </div>


                    </div>
                    <n-button type="primary" outline  round @click.native="Regresar()">
                    <i class="now-ui-icons arrows-1_minimal-left"></i> Regresar
                  </n-button>
                </card>
        </div>
    </div>
  </div>
</template>
<script>
import {
  Badge,
  Button,
  InfoSection,
  FormGroupInput,
  Card,
  Tabs,
  TabPane,
  Checkbox,
} from "@/components";
import { API_KEY } from "@/constants";
import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";
import { Table, TableColumn, Tooltip } from "element-ui";
import store from "../store";

Vue.use(VueGoogleMaps, {
  load: {
    key: API_KEY,
  },
});
export default {
  computed: {
    representacion() {
      return store.state.representacion;
    },
    Ejecutivos() {
      return store.state.Ejecutivos;
    },
  },
  name: "profile-page",
  bodyClass: "profile-page",
  components: {
    InfoSection,
    Card,
    Tabs,
    TabPane,
    Badge,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [FormGroupInput.name]: FormGroupInput,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      Ubicaciones: [],
      form: {
        firstName: "",
        email: "",
        notRobot: false,
      },
      center: {
        lat: 20.67441699990758,
        lng: -103.42438250692092,
      },
      options: {
        styles: [
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [
              {
                color: "#e9e9e9",
              },
              {
                lightness: 17,
              },
            ],
          },
        ],
      },
      AuxRepresentacion: {},
      UsuariosData: [],
      ObjetoClass: {
        mapa: {
          height: this.CalculaMapa(),
        },
      },
    };
  }, //
  methods: {
    CalculaMapa() {
      let tamanio = 0; //
      // if (this.UsuariosData.length == 2 || this.UsuariosData.length == 0){
      //   tamanio = 0;
      // }else if(this.UsuariosData.length > 2){

      //   tamanio = 1000;
      // }
      return tamanio;
    },
    Regresar() {
      let Auxrepresentacion = {
        representacionId: null,
        representacionNombre: null,
        direccion: null,
        telefonoOficina: null,
        oficinaEmisora: null,
        porcentaje: null,
        porcentajeAsesor: null,
        porcentajeEjecutivo: null,
        foranea: null,
        latitud: null,
        longitud: null,
      };
      store.state.representacion = Auxrepresentacion;
      store.state.seeAll = false;
    },
  },
  created() {
    this.AuxRepresentacion = this.representacion;
    this.center.lat = parseFloat(this.AuxRepresentacion.latitud);
    this.center.lng = parseFloat(this.AuxRepresentacion.longitud);
    //console.log(this.AuxRepresentacion);
    //this.Usuarios(this.AuxRepresentacion.representacionId);
    console.log(VueGoogleMaps);
  },
};
</script>
<style>
.tarjeta {
  margin-top: -12%;
  overflow-y: scroll;
  height: 500px;
}
.photo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  margin: -5% auto -6%;
  box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.3);
}
</style>
