<template>
  <!--     *********     BLOGS 3      *********      -->
  <div class="col-md-8 ml-auto mr-auto">
    <h2 class="title text-center colorTxt">Nuestros Servicios</h2>
    <card type="blog" plain>
      <div class="row">
        <div class="col-md-5">
          <div class="card-image">
            <br />
            <img img v-lazy="arrendamiento" />
          </div>
        </div>
        <div class="col-md-7">
          <h3 class="card-title">
            <strong>
              <a>Arrendamiento Seguro</a>
            </strong>
          </h3>
          <h4 class="card-description text-justify">
            <strong>
              Póliza Jurídica minimizamos el riesgo para un arrendamiento
              seguro, gracias a los diferentes procesos de calidad con los que
              cuenta la empresa. Desarrollamos contratos de arrendamiento
              considerando los acuerdos entre las partes, así mismo nos
              actualizamos constantemente conforme a los ordenamientos y
              reformas de cada estado, con ello garantizamos que el
              arrendamiento se hará de manera segura.<br />
            </strong>
          </h4>
        </div>
      </div>
    </card>
    <div class="row justify-content-center">
      <div class="col-md-6">
        <card type="blog" plain>
          <div class="card-image">
            <img img v-lazy="capacitaciones" />
          </div>
          <div class="card-body">
            <h3 class="card-title">
              <strong>
                <a>Capacitaciones</a>
              </strong>
            </h3>
            <h4 class="card-description text-justify">
              <strong>
                En Póliza Jurídica impartimos cursos calendarizados al gremio
                inmobiliario con la finalidad de compartir experiencias y
                prácticas adecuadas, así mismo adecuamos planes de capacitación
                integral a tu inmobiliaria o asociación en temas diversos.
              </strong>
            </h4>
          </div>
        </card>
      </div>
      <div class="col-md-6">
        <card type="blog" plain>
          <div class="card-image">
            <img img v-lazy="asesoria" />
          </div>
          <div class="card-body">
            <h3 class="card-title">
              <strong>
                <a>Asesoría Jurídica</a>
              </strong>
            </h3>
            <h4 class="card-description text-justify">
              <strong>
                Para el equipo de Póliza Jurídica la parte fundamental es
                asesorarte de tal forma que nos convertimos en tu bufete
                jurídico por lo que nuestro grupo de expertos estará disponible
                para ti sin ningún costo.
              </strong>
            </h4>
          </div>
        </card>
      </div>
    </div>
    <card type="blog" plain>
      <div class="row">
        <div class="col-md-5">
          <div class="card-image">
            <br />
            <img img v-lazy="investigacion" />
          </div>
        </div>
        <div class="col-md-7">
          <h3 class="card-title">
            <strong>
              <a>Investigación y Validación</a>
            </strong>
          </h3>
          <h4 class="card-description text-justify">
            <strong>
              La prevención es una práctica cada vez más necesaria, el
              arrendamiento es un riesgo y para minimizar este riesgo es
              imprescindible realizar una adecuada validación y en su caso
              investigación de los prospectos (arrendatarios y fiadores).
              Validamos e investigamos en nuestras plataformas que no tengan
              actividad judicial, a demás de verificar la personalidad y
              solvencia económica de los firmantes.
            </strong>
          </h4>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import { Card } from "@/components";
import arrendamiento from "../../public/imagenes/arrendamiento.webp";
import capacitaciones from "../../public/imagenes/capacitaciones.webp";
import asesoria from "../../public/imagenes/asesoria.webp";
import investigacion from "../../public/imagenes/investigacion.webp";
export default {
  components: {
    Card,
  },
  data() {
    return {
      arrendamiento: arrendamiento,
      asesoria: asesoria,
      capacitaciones: capacitaciones,
      investigacion: investigacion,
    };
  },
};
</script>

<style>
.colorCard {
  background-color: #87211f;
}
.colorTxt {
  color: rgb(144, 25, 23);
}
</style>