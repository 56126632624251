<template>
  <div class="wrapper">
    <div class="pricing-4">
      <div class="container">
        <div class="row">
          <div class="col-md-6 ml-auto mr-auto text-center">
            <h2 class="title colorTxt">Nuestros precios</h2>
            <fg-input
              placeholder="$ Importe Renta "
              v-model="inputVal"
              @change="revisar"
            ></fg-input>
             <h5 class="description">
                <strong>
            Todos nuestros precios son con I.V.A. incluido.
             </strong>
          </h5>
          </div>
          
        </div>
        <div class="row">
          <div class="col-md-12 ml-auto mr-auto">
            <div class="row spacio">
              <div class="col-md-6">
                <card type="pricing" raised>
                  <h4 class="title colorTxt">Póliza Tradicional</h4>
                  <h1 class="card-title">
                    <small>$</small>
                    {{ tradicional }}
                  </h1>
                  <div class="row">
                    <div class="col-md-6">
                       <strong>
                      <ul>
                        <li>
                          Investigación y Validación de la solvencia del
                          Arrendatario (Prospecto).
                        </li>
                        <li>
                          Elaboración de Contrato de Arrendamiento Actualizado.
                        </li>
                        <li>Desalojo de Arrendatario por incumplimiento.</li>
                        
                      </ul>
                       </strong>
                    </div>
                    <div class="col-md-6">
                       <strong>
                      <ul>
                        <li>Sín pagos extras ni deducibles.</li>
                        <li>Prevención ante Ley de Extinción de Dominio.</li>
                        <li>Con ó sin Fiador.</li>
                        <li>Escalable a Plus (Aplica restricciones).</li>
                        
                      </ul>
                      </strong>
                    </div>
                     
                  </div>
                </card>
              </div>
              <div class="col-md-6">
                <card type="pricing" raised>
                  <h4 class="title colorTxt">Póliza Plus</h4>
                  <h1 class="card-title">
                    <small>$</small>
                    {{ plus }}
                  </h1>
                  <div class="row">
                    <div class="col-md-6">
                      <strong>
                      <ul>
                        <li>
                          Investigación y Validación de la solvencia del
                          Arrendatario (Prospecto).
                        </li>
                        <li>
                          Elaboración de Contrato de Arrendamiento Actualizado.
                        </li>
                        <li>Desalojo de Arrendatario por incumplimiento.</li>
                        
                      </ul>
                      </strong>
                    </div>
                    <div class="col-md-6">
                      <strong>
                      <ul>
                        <li>Sín pagos extras ni deducibles.</li>
                        <li>Prevención ante Ley de Extinción de Dominio.</li>
                         <li>Con Fiador solvente.</li>
                        <li>Recuperación de Rentas y Servicios no pagados.</li>
                       
                      </ul>
                      </strong>
                    </div>
                  </div>
                </card>
              </div>
            </div>
          </div>
        </div>
      
      </div>
    </div>
  </div>
</template>

<script>
import { FormGroupInput, Card } from "@/components";
import cc from "../Servicios/CentroCostos";
export default {
  components: {
    Card,
    [FormGroupInput.name]: FormGroupInput,
  },
  data() {
    return {
      inputVal: null,
      centrocostos: null,
      tradicional: 0,
      plus: 0,
    };
  },
  methods: {
    retrive() {
      cc.listado()
        .then((r) => {
          this.centrocostos = r.data;
          //console.log(this.centrocostos);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    revisar() {
      this.centrocostos.forEach((c) => {
        if (
          c.centroCostosTipo == "Tradicional" &&
          this.inputVal >= c.centroCostosRentaInicial &&
          this.inputVal <= c.centroCostosRentaFinal
        ) {
          this.tradicional = c.centroCostosMonto;
        }
        if (
          c.centroCostosTipo == "Plus" &&
          this.inputVal >= c.centroCostosRentaInicial &&
          this.inputVal <= c.centroCostosRentaFinal
        ) {
          this.plus = c.centroCostosMonto;
        }
      });
    },
  },
  created() {
    this.retrive();
  },
};
</script>

<style>
.spacio {
  margin-top: 4%;
}
.colorTxt {
  color: rgb(144, 25, 23);
}
</style>