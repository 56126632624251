<template>
  <div class="cd-section" id="quienessomos">
    <div class="container">
      <div class="row">
        <div class="col-md-8 ml-auto mr-auto text-center">
          <h2 class="title colorTxt" >¿Quiénes somos?</h2>
          <h3 class="description">
             <strong>
            	Somos una empresa 100% mexicana, <br> debidamente constituida.
               </strong>
          </h3>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="tablet-container">
            <img v-lazy="ilustracionSomos" />
          </div>
        </div>
        <div class="col-md-4">
          <div class="info info-vertical ">
             <div class="icon icon-primary icon-circle">
              <i class="now-ui-icons ui-1_calendar-60"></i>
            </div>
            <div class="description">
              <h5 class="info-title">Con más de 17 años de experiencia.</h5>
            </div>
            <div class="icon icon-primary icon-circle">
              <i class="now-ui-icons location_pin"></i>
            </div>
            
            <div class="description">
              <h5 class="info-title">Presencia a Nivel Nacional..</h5>
            </div>
            <div class="icon icon-primary icon-circle">
              <i class="now-ui-icons business_bank"></i>
            </div>
            
            <div class="description">
              <h5 class="info-title">Con más de 30 oficinas.</h5>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import ilustracion from "../../public/imagenes/ilustracionSomos.webp";
export default {
  data() {
    return {
      ilustracionSomos: ilustracion
    };
  },
};
</script>

<style>
.colorTxt {
  color: rgb(144, 25, 23);
}
</style>