<template>
  <div class="section section-tabs">
    <div class="container">
      <div class="col-md-6 ml-auto mr-auto text-center">
        <h2 class="title colorTxt">Requisitos</h2>
      </div>
      <div class="row">
        <div class="col-md-12 ml-auto col-xl-12 mr-auto">
          <h3 class="description text-center">
            <strong>
              Los requisitos para tramitar una Póliza Jurídica son los
              siguientes:
            </strong>
          </h3>
          <!-- Nav tabs -->
          <card>
            <tabs
              slot="raw-content"
              tab-content-classes="tab-content-padding "
              type="primary"
            >
              <tab-pane>
                <template slot="label">
                  <i class="now-ui-icons shopping_shop"></i> Arrendatario
                  (Persona Física)
                </template>

                <p>
                  Llenar formato correspondiente (SOLICITUD DE PERSONA FÍSICA).
                </p>
                <p>
                  Identificación oficial, INE o Pasaporte (en caso de ser
                  extranjero documento migratorio vigente).
                </p>
                <p>Ùltimo comprobante de domicilio.</p>
                <p>
                  Comprobante de ingresos (nómina) ó estados de cuenta bancarios
                  (últimos 3 meses no aplica tarjeta de crédito ni cuenta de
                  inversión).
                </p>
              </tab-pane>

              <tab-pane>
                <template slot="label">
                  <i class="now-ui-icons shopping_shop"></i> Arrendatario
                  (Persona Moral)
                </template>
                <p>
                  Llenar formato correspondiente (SOLICITUD DE PERSONA MORAL).
                </p>
                <p>Acta constitutiva legal de la empresa.</p>
                <p>RFC (Hoja de inscripción).</p>
                <p>Poder notarial del representante legal de la empresa</p>
                <p>
                  Identificación oficial del representante legal, INE o
                  Pasaporte
                </p>
                <p>Ùltimo comprobante de domicilio.</p>
                <p>
                  Comprobante de ingresos (los últimos 3 estados bancarios de la
                  empresa o 3 declaraciones de impuestos)
                </p>
              </tab-pane>
              <tab-pane>
                <template slot="label">
                  <i class="now-ui-icons shopping_shop"></i> Fiador (Persona
                  Física)
                </template>
                <p>Identificación oficial. INE o Pasaporte</p>
                <p>Ùltimo comprobante de domicilio.</p>
                <p>
                  Escritura del inmueble en garantía (libre de gravamen) con
                  número de folio visible del registro público de la propiedad
                </p>
                <p>
                  Acta de matrimonio (identificación del cónyuge en caso de
                  estar casado por sociedad conyugal)
                </p>
                <p>Copia del predial</p>
              </tab-pane>
              <tab-pane>
                <template slot="label">
                  <i class="now-ui-icons shopping_shop"></i> Fiador (Persona
                  Moral)
                </template>
                <p>Acta Constitutiva de la Empresa.</p>
                <p>RFC de la Empresa</p>
                <p>Ùltimo comprobante de domicilio.</p>
                <p>Poder del Representante Legal</p>
                <p>Identificación del Representante Legal</p>
                <p>Copia de escritura del inmueble en garantía</p>
                <p>Pago del último predial</p>
              </tab-pane>
            </tabs>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Card, Tabs, TabPane } from "@/components";

export default {
  components: {
    Card,
    Tabs,
    TabPane,
  },
};
</script>
<style>
.tab-content.tab-content-padding {
  padding: 20px;
}
.color-txt {
  color: #87211f;
}
</style>
