<template>
  <div class="wrapper">
    <div class="section-space"></div>
    <carousel-section id="inicio"></carousel-section>
    <quienes-somos id="quienessomos"></quienes-somos>
    <porque-nosotros id="porquenosotros"></porque-nosotros>
    <our-services id="ourservices"></our-services>
    <pricing id="pricing"></pricing>
    <requisitos id="requisitos"></requisitos>
    <solicitudes id="solicitudes"></solicitudes>
    <encuentranos v-if="representacion.representacionNombre == null" id="findus"></encuentranos>
    <mapa-representacion v-else id="findus"></mapa-representacion>
    <eventossection id="eventos"></eventossection>
    <!-- <div class="features-3" v-else>
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto">
            <h2 class="title colorTxt">Proximamente mas pláticas...</h2>
          </div>
        </div>
      </div>
    </div> -->
    <SocialChat icon :attendants="attendants" class="whatsapp-chat" @open="ChatOpen">
      <p slot="header">
        Click para ser atendido por WhatsApp con algunos de nuestros ejecutivos.
      </p>
      <template v-slot:button>
        <img src="https://raw.githubusercontent.com/ktquez/vue-social-chat/master/src/icons/whatsapp.svg"
          alt="icon whatsapp" aria-hidden="true" />
      </template>
      <small slot="footer">Horario de Atención: 9am a 6pm</small>
    </SocialChat>
  </div>
</template>
<script>
import encuentranos from "./findus";
import Pricing from "./pricing";
import Solicitudes from "./solicitudes";
import PorqueNosotros from "./whychooseus";
import OurServices from "./ourservices";
import QuienesSomos from "./quienessomos";
import CarouselSection from "./slide";
import eventossection from "./Eventos";
import Requisitos from "./requisitos";
import store from "../store";
import MapaRepresentacion from "./maparepresentacion";
import Evento from "../Servicios/Eventos"; //cambios
import acceptLanguage from "accept-language";

import iframeRepresentacion from "./representacioniframe";
import chapultepeciframe from "./chapultepeciframe";
import { SocialChat } from "vue-social-chat"; //whatsapp
import ServUsuarios from "../Servicios/Usuarios";
import logoejecutivo from "../../public/imagenes/ejecutivo1.webp";
import ServicioRepresentacion from "../Servicios/Representaciones";
export default {
  computed: {
    representacion() {
      return store.state.representacion;
    },
    representaciones() {
      return store.state.representaciones;
    },
    slides() {
      return store.state.slides;
    },
  },
  name: "landing-page",
  bodyClass: "landing-page",
  components: {
    encuentranos,
    Pricing,
    Solicitudes,
    PorqueNosotros,
    OurServices,
    QuienesSomos,
    CarouselSection,
    eventossection,
    Requisitos,
    MapaRepresentacion,
    acceptLanguage,
    iframeRepresentacion,
    SocialChat,
    chapultepeciframe,
  },
  data() {
    return {
      attendants: [
        {
          app: "whatsapp",
          label: "Ejecutiva",
          name: "Matriz",
          number: "525539732052",

          avatar: {
            src: logoejecutivo,
            alt: 'contacto a Póliza Jurídica',
          },
        }
      ],
    };
  },
  methods: {
    ChatOpen() {
      console.log("registramosGoogle");
    },
    async listado() {
      Evento.listado()
        .then((r) => {
          store.state.slides = r.data;
        })
        .catch((e) => {
          console.log(
            "Error en el servicio de listado de representaciones - INICIO"
          );
          console.log(e);
        });
    },
    Usuarios(id) {
      ServUsuarios.listado(id)
        .then((u) => {
          store.state.Ejecutivos = u.data;
        })
        .catch((e) => {
          //console.log(e);
        });
    },
    asignamosRepresentacion(representacion) {
      switch (representacion) {
        // case "chapultepec":
        //   this.$router.push("/Chapultepec/inicio");
        //   break;
        case "sanangel":
          this.$router.push("/SanAngel/inicio");
          break;
        default:
          if (this.representaciones.length > 0) {
            //console.log("representaciones", this.representaciones);
            this.representaciones.forEach((r) => {
              //console.log("datanombre", r.representacionNombre);
              let nombre = r.representacionNombre;
              //console.log("nombre", nombre);
              let regex = nombre.toLowerCase();
              //console.log("regex", regex);
              regex = regex.normalize("NFD").replace(/[\u0300-\u036f]/g, ""); //elimina los acentos
              regex = regex.replace(/\s+/g, ""); //elimina los espacio
              //console.log(regex);
              var str = regex.search(representacion);
              if (str == 0) {
                let telefono = r.telefonoOficina;
                if (telefono != null || telefono != undefined) {
                  telefono = telefono.replace(/\s+/g, ""); //elimina los espacio
                } else {
                  telefono = "";
                }
                this.attendants[0].label = "Representación";
                this.attendants[0].name = r.representacionNombre;
                this.attendants[0].number = "+52" + telefono;
                let representacion = {
                  representacionId: r.representacionId,
                  representacionNombre: r.representacionNombre,
                  direccion: r.direccion,
                  telefonoOficina: r.telefonoOficina,
                  oficinaEmisora: null,
                  porcentaje: null,
                  porcentajeAsesor: null,
                  porcentajeEjecutivo: null,
                  foranea: null,
                  latitud: r.latitud,
                  longitud: r.longitud,
                }; //
                store.state.representacion = representacion;
                this.Usuarios(r.representacionId);
              }
            });
          }
          break;
      }
    },
    RetriveRepresentaciones() {
      ServicioRepresentacion.listado()
        .then((r) => {
          store.state.representaciones = r.data;
          let representacion = this.$route.params.representacion;
          //console.log(representacion);
          if (
            representacion != null ||
            (representacion != undefined && r.data.length > 0)
          ) {
            this.asignamosRepresentacion(representacion);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  created() {
    this.listado();
    this.RetriveRepresentaciones();
    acceptLanguage.languages(["es-MX", "en-US"]);
    store.state.Language = acceptLanguage.get();
  },
  mounted() { },
  watch: {
    $route(to) {
      //Vamos a interpretar
      let representacion = to.params.representacion;
      if (representacion != null || representacion != undefined) {
        this.asignamosRepresentacion(representacion.toLowerCase());
      }
    },
  },
};
</script>
<style>
.section-space-oficina {
  height: 50px;
  display: block;
}

.Titulo {
  font-size: 22px;
  color: black;
}

.centrar {
  justify-content: center;
}

.whatsapp-chat {
  --whatsapp: #46c056;
  --vsc-bg-header: var(--whatsapp);
  --vsc-bg-button: var(--whatsapp);
  --vsc-outline-color: var(--whatsapp);
}
</style>
