<template>
  <div class="blogs-5" data-background-color="grey"  >
    <div class="container">      
      <div class="row">        
          
        <div class="col-md-10 ml-auto mr-auto">  
          <h2 class="title colorTxt">Eventos</h2>                   
          <div class="row">          
                
            <vueper-slides
              class="tamanio"
              :visible-slides="3"
              :arrows="true"
              :slide-ratio="1 / 1.4"
              :gap="3"
              :dragging-distance="70"
            >
              <vueper-slide v-for="i in slides" :key="i.eventosId" class="container-sm">
                <template v-slot:content >
                  <keep-alive>
                    <cardEvento
                      :Evento="i"
                      v-bind:modals="modals"
                      v-bind:Id="Id"
                    >                    
                  </cardEvento>
                  </keep-alive>
                </template>
              </vueper-slide>
            </vueper-slides>
          </div>
        </div>
      </div>
    </div>
    <modal-registrarse
      v-if="modals.classic == true"
      v-bind:modals="modals"
      v-bind:Id="Id"
    ></modal-registrarse>
  </div>
</template>

<script>
import { Card, Button, Modal, FormGroupInput } from "@/components";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import cardEvento from "./cardEvento";
import store from "../store";
import ModalRegistrarse from "./Registrarse";

export default {
  computed: {
    slides() {
      return store.state.slides;
    },
  },
  components: {
    Card,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    VueperSlides,
    VueperSlide,
    cardEvento,
    Modal,
    ModalRegistrarse,
  },
  data() {
    return {
      slider: [],
      modals: {
        classic: false,
      },
      Id: 0,
      form: {
        firstName: "",
        lastName: "",
        email: "",
        agree: false,
      },
    };
  },
  methods: {
    //slides() {},
    Regresar() {
      //console.log("Entro");
    },
  },
  created() {
    //this.listado();
  },
};
</script>

<style scoped>
.tamanio {
  height: 100%;
  width: 100%;
}
.Input-color {
  color: black;
}
.colorTxt {
  color: #87211f
}
</style>