<template>
  <modal :show.sync="modals.classic" headerClasses="justify-content-center">
    <template>
      <div class="row">
        <div class="col-12">
          <fg-input
            class="inputcolor"
            placeholder="First Name..."
            addon-left-icon="now-ui-icons users_circle-08"
          >
          </fg-input>
        </div>
        <div class="col-12">
          <fg-input
            class=""
            placeholder="Last Name..."
            addon-left-icon="now-ui-icons text_caps-small"
          >
          </fg-input>
        </div>
      </div>
    </template>
    <div slot="footer" class="justify-content-center">
      <n-button type="primary" round @click.native="modals.classic = false"
        >Cerrar</n-button
      >
    </div>
  </modal>
</template>

<script>
import {
  Card,
  Button,
  Badge,
  InfoSection,
  FormGroupInput,
  Checkbox,
  Modal,
} from "@/components";
import { Select, Option } from "element-ui";

export default {
  props: ["modals", "Id"],
  name: "about-us",
  bodyClass: "about-us",
  components: {
    Card,
    InfoSection,
    Badge,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [FormGroupInput.name]: FormGroupInput,
    [Select.name]: Select,
    [Option.name]: Option,
    Modal,
  },
  data() {
    return {
      slider: [],
      modals: {
        classic: true,
      },
      Id: 0,
      form: {
        firstName: "",
        lastName: "",
        email: "",
        agree: false,
      },
      InputStyle: {
        color: 'black',
        backgroundColor: 'rgba(255, 255, 255, 0.85)',
      }
    };
  },
};
</script>

<style>
.inputcolor{
      border-color: #343a40;
    color: #343a40;
}
</style>